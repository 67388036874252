<template>
  <RevInputAutocomplete
    v-model="query"
    v-bind="$attrs"
    :label="$attrs.label as string"
    :options="
      currentOptions as [OptionB?, OptionB?, OptionB?, OptionB?, OptionB?]
    "
    @input="handleModelInput"
    @select-item="
      (option, _, index) =>
        handleSelectItem(option as unknown as Option, _, index)
    "
  >
    <template #default="item">
      <RevListItemInteractive
        :id="item?.id"
        class="p-12"
        :index="item?.index"
        :place-id="item?.placeId"
        :role="item?.role"
        @click="item?.onClick"
        @mousedown="item?.onMousedown"
        @mousemove="item?.onMousemove"
      >
        <template #label>
          <span
            v-for="(label, index) in item?.labels as Option['labels']"
            :key="`${label.text}-${index}`"
            :class="label.class"
            >{{ label.text }}</span
          ></template
        >
      </RevListItemInteractive>
    </template>
  </RevInputAutocomplete>
</template>

<script setup lang="ts">
import { useRoute } from '#imports'
import { computed, ref } from 'vue'

import type { Country, MarketCountryCode } from '@backmarket/http-api'
import { $httpFetch } from '@backmarket/nuxt-module-http/$httpFetch'
import { useI18nLocale } from '@backmarket/nuxt-module-i18n/useI18nLocale'
import { useLogger } from '@backmarket/nuxt-module-logger/useLogger'
import { useMarketplace } from '@backmarket/nuxt-module-marketplace/useMarketplace'
import { useDebounceFn } from '@backmarket/utils/composables/useDebouncedFn'
import { RevInputAutocomplete } from '@ds/components/InputAutocomplete'
import { RevListItemInteractive } from '@ds/components/ListItemInteractive'

import {
  ADDRESS_AUTOCOMPLETE_DEBOUNCE_DELAY,
  ADDRESS_AUTOCOMPLETE_MINIMUM_LENGTH,
} from '../../constants/autocomplete'
import { type Option } from '../../types/bmPlaces'
import { FeatureCode } from '../../types/featureCode'
import { BmPlaces as BmPlacesClient } from '../../utils/bmPlaces/bmPlaces'

export type AddressAutocompleteProps = {
  country?: Country | MarketCountryCode | null
  featureCode: FeatureCode
  autocompleteMaxItems?: number
}

const props = withDefaults(defineProps<AddressAutocompleteProps>(), {
  country: null,
  autocompleteMaxItems: 5,
})

const emit = defineEmits(['options-update', 'select-item'])

defineOptions({
  inheritAttrs: false,
})

const options = ref<{ [query: string]: Option[] }>({})
const query = ref('')

const logger = useLogger()

const currentCountry = useMarketplace()?.market?.countryCode

const searchClient = computed(
  () =>
    new BmPlacesClient({
      country: props.country ?? currentCountry,
      language: useI18nLocale(),
      httpFetch: $httpFetch,
    }),
)

export interface OptionB {
  key?: string
  label: string
}

const currentOptions = computed(() => {
  // We didn't find any other workaround to limit to 5 items as the source can actually have more
  return (
    options.value[query.value]
      ? options.value[query.value].slice(0, props.autocompleteMaxItems)
      : []
  ) as [Option?, Option?, Option?, Option?, Option?]
})

const handleModelInput = useDebounceFn(async function updateOptions() {
  try {
    if (!props.featureCode) {
      logger.error('[Address Autocomplete] FeatureCode is missing', {
        route: useRoute().name,
      })
    }

    const optionsFromAPI =
      query.value.length >= ADDRESS_AUTOCOMPLETE_MINIMUM_LENGTH
        ? await searchClient.value.getOptions({
            query: query.value,
            featureCode: props.featureCode,
          })
        : []

    emit('options-update', {
      options: optionsFromAPI,
      query: query.value,
      hasMinimumLength:
        query.value.length >= ADDRESS_AUTOCOMPLETE_MINIMUM_LENGTH,
    })

    options.value = { ...options.value, [query.value]: optionsFromAPI }
  } catch (error) {
    // Cancelled: do nothing
    // Errored: already logged using API SDK
  }
}, ADDRESS_AUTOCOMPLETE_DEBOUNCE_DELAY)

async function handleSelectItem(option: Option, _: unknown, index: number) {
  query.value = option.labels.map(({ text }) => text).join('')

  const selectedItem = await searchClient.value.getDetails(
    option,
    props.featureCode,
  )

  return emit('select-item', selectedItem, index)
}
</script>
